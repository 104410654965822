import { call, put, debounce, throttle, takeLatest, select, delay } from 'redux-saga/effects'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import qs from 'querystring'
import downloadString from 'lib/download-string'
import { webApi, api } from 'lib/api'
import { errorWrap } from 'lib/saga-helpers'
import { constants, loadUser as loadUserAction, fetchDiscount as fetchDiscountAction, fetchGeolocation, toggleModal } from './actions'
import { initializePostHog, resetPostHog } from 'client/lib/posthog'
import * as analytics from 'lib/analytics'
import * as rewardful from 'lib/rewardful'
import { fetchDropDownNotificationItems, fetchNotifications } from 'store/notifications/actions'
import { assistantUpdateUsage } from 'store/assistant/actions'
import { handleReferral } from 'store/ui/actions'
import { userIsAtLeast } from 'lib/user'
import toast from 'react-hot-toast'
import { errorToast, infoToast, successToast } from 'lib/toast'
import { PLANS } from '../../../server/lib/constants'
import { getLocalStorage } from '../../lib/get-local-storage'

const SERVER = typeof window === 'undefined'

export const selectors = {
  referencesToExport: ({ ui }) => ui.referencesToExport,
  referralPartner: ({ ui }) => ui.referralPartner,
  agAvailabilityQueue: ({ ui }) => ui.agAvailabilityQueue,
  fullTextLinkQueue: ({ ui }) => ui.fullTextLinkQueue
}

export function * getNewToken () {
  const localStorage = getLocalStorage()
  try {
    const { data: newToken } = yield call(webApi.get, '/auth/api_token')
    localStorage?.setItem('apiToken', newToken)
  } catch (e) {
    console.error('could not get token from API, bad scitewebapp JWT?', e)
  }
}

//
// For the case where the user has a token
// not minted by the Node backend.
//
// In this case the token claims are not necesseraly
// derived from the user object. This can be used for
// anonymous users to make claims on restricted API resources,
// and by authenticated users to escalate their permissions.
//
function * loadAnonToken ({ payload: token }) {
  window.sessionStorage.setItem('anonApiToken', token)
}

//
// Finalize user setup once they are loaded from
// express session and have correct API token
//
function * _setupUser (user) {
  yield put(fetchNotifications())
  yield put(fetchDropDownNotificationItems())

  //
  // Generally for any ag-sso accounts we want to show the AG link resolver
  // But this caused weirdness for a re-seller because they want to demo their own,
  //    which is configured on their license but overriden because one of their accounts
  //    got auto-magically upgraded to ag-sso.
  // The org license check is a hotfix to unblock them until we find a longer term solution.
  //
  if (user.signupType === 'ag-sso' && user.organizationLicense !== 'sunmedia-reseller-partner-in-japan') {
    yield put(handleReferral('reprintsdesk.com'))
  }
  analytics.setUser(user)
}

function * loadUser ({ payload: { query = {} } = {} }) {
  if (SERVER) {
    return
  }

  const { localStorage, location } = window
  const { autologin, ...restQuery } = query
  const currentToken = localStorage?.getItem('apiToken')
  let user = null

  try {
    const res = yield call(webApi.get, '/users/me')
    user = res.data
    if (user.assistantUsage) {
      yield put(assistantUpdateUsage(user.assistantUsage))
    }
  } catch (e) {
    const status = e?.response?.status
    if (status === 404) {
      localStorage.removeItem('apiToken')
    }
    yield put({
      type: constants.LOAD_USER_FAILURE,
      error: e
    })
  }

  if (autologin === 'azure' && !userIsAtLeast(user, PLANS.premium)) {
    //
    // Remove autologin from the query so we don't accidentally
    // end up in an infinite loop somehow
    //
    const redirectQuery = qs.stringify(restQuery)
    if (redirectQuery) {
      window.history.replaceState(null, null, window.location.pathname + `?${redirectQuery}`)
    } else {
      window.history.replaceState(null, null, window.location.pathname)
    }
    location.pathname = '/api/auth/azuread'
    return
  }

  yield put(fetchGeolocation())

  if (!user) {
    yield put({ type: constants.LOAD_USER_FAILURE })
    return
  }

  try {
    const currentClaims = currentToken ? jwtDecode(currentToken) : {}
    const differentUser = currentClaims?.email !== user.email
    const differentPlan = currentClaims?.plan !== user.subscription.plan

    if (currentToken &&
        currentClaims !== null &&
        !differentUser &&
        !differentPlan &&
        currentClaims.version &&
        currentClaims.version === CONFIG.apiWebUser.jwtVersion
    ) {
      yield put({
        type: constants.LOAD_USER_SUCCESS,
        payload: user
      })
      yield _setupUser(user)
      return
    }

    yield getNewToken()
    yield put({
      type: constants.LOAD_USER_SUCCESS,
      payload: user
    })
    yield _setupUser(user)
  } catch (e) {
    yield put({
      type: constants.LOAD_USER_FAILURE,
      error: e
    })
    if (window.Rollbar) {
      Rollbar.error('Could not load user', e)
    }
  }
}

function * saveUser ({ payload: { slug, ...rest } }) {
  const { data: savedUser } = yield call(webApi.patch, `/users/${slug}`, { ...rest })
  yield put({ type: constants.SAVE_USER_SUCCESS, data: savedUser })
  yield put(loadUserAction())
}

function * logout () {
  yield call(webApi.get, '/users/logout')
  const localStorage = getLocalStorage()
  localStorage?.removeItem('apiToken')
  yield put({
    type: constants.LOGOUT_SUCCESS
  })

  analytics.event({
    category: 'User',
    action: 'Logged out'
  }, { pushToPosthog: false })
  resetPostHog()
}

function * deleteUser ({ payload: { history } }) {
  // ensure we delete any subscription as well.
  const infoToastId = infoToast({
    message: 'Deleting your account, hang tight...',
    durationMs: 20000
  })

  yield call(webApi.post, '/checkout/subscription/delete', { deleteAccount: true })
  yield call(api.delete, '/users/delete')
  const localStorage = getLocalStorage()
  localStorage.removeItem('apiToken')
  yield put({
    type: constants.DELETE_USER_SUCCESS
  })

  toast.dismiss(infoToastId)

  successToast({
    message: 'Successfully deleted your account.',
    durationMs: 3000
  })

  history.push('/')
  analytics.event({
    category: 'User',
    action: 'user deleted'
  })
}

function * fetchOnboarding ({ payload: slug }) {
  const { data } = yield call(api.get, `/users/slug/${slug}/onboarding`)

  yield put({
    type: constants.FETCH_ONBOARDING_SUCCESS,
    payload: data
  })
}

export function * getEntitlements () {
  const { data } = yield call(api.get, '/entitlements/entitlement')
  yield put({
    type: constants.GET_ENTITLEMENTS_SUCCESS,
    payload: data
  })
}

export function * saveReferrals ({ payload: { referrals } }) {
  try {
    const { data } = yield call(api.post, '/users/referral', { referrals })
    yield put({
      type: constants.SAVE_REFERRALS_SUCCESS,
      payload: data
    })
  } catch (e) {
    console.error('failed saving referrals for user', e)
    yield put({
      type: constants.SAVE_REFERRALS_FAILURE
    })
  }
  try {
    yield call(webApi.put, '/checkout/subscription/grant-referral-benefits')
    yield loadUser()
  } catch (e) {
    console.error('failed granting referral benefits for user', e)
  }
}

export function * checkOrganizationLicense () {
  const { data: { organizationLicense } } = yield call(webApi.get, '/auth/check_license')
  yield put({
    type: constants.CHECK_PREMIUM_IP_SUCCESS,
    payload: organizationLicense
  })
}

function * redeemCode ({ payload }) {
  const { data } = yield call(webApi.post, '/checkout/subscription/redeem-code', { code: payload })
  if (data.error) {
    yield put({
      type: constants.REDEEM_CODE_ERROR,
      payload: 'An error occurred redeeming the code, please contact support or try again later.'
    })
    return
  }
  analytics.event({
    category: 'Checkout',
    action: 'Redeem Code',
    label: payload
  })
  yield put({
    type: constants.REDEEM_CODE_SUCCESS,
    payload: data
  })
}

function * applyDiscount ({ payload }) {
  const infoToastId = infoToast({
    message: 'Applying coupon, hang tight...',
    durationMs: 20000
  })
  try {
    yield call(webApi.post, '/checkout/subscription/apply-discount', { ...payload })
    toast.dismiss(infoToastId)
    successToast({
      message: `Successfully applied ${payload.percentAmountOff}% off discount to your account, this will take effect on the next invoice.`,
      durationMs: 4000
    })
    yield put({
      type: constants.APPLY_DISCOUNT_SUCCESS
    })
    yield put(fetchDiscountAction())
  } catch (e) {
    toast.dismiss(infoToastId)
    errorToast({
      message: 'Failed to apply discount. Please contact customersupport@researchsolutions.com.',
      durationMs: 4000
    })
    yield put({
      type: constants.APPLY_DISCOUNT_FAILURE,
      payload: 'An error occurred applying the discount, please contact support or try again later.'
    })
  }
}

function * fetchDiscount () {
  const { data } = yield call(webApi.get, '/checkout/subscription/fetch-discount')
  yield put({
    type: constants.FETCH_DISCOUNT_SUCCESS,
    payload: data
  })
}

function * completeOnboardingStep ({ payload: { category, step, email } }) {
  const res = yield call(api.put, '/users/onboarding', { category, step, email })
  yield put({
    type: constants.LOAD_USER_SUCCESS,
    payload: res.data
  })
}

function * fetchAutocompletes ({ payload: { term, field, includeFields } }) {
  const params = {}

  if (term?.length && term.length > 1000) {
    console.warn('Too many characters to autocomplete')
    return
  }

  if (term) params.prefix = term.slice(0)
  if (field) params.field = field
  if (includeFields) params.include_fields = includeFields

  const { data: { autocompletes } } = yield call(api.get, '/search/autocompletes', { params })

  yield put({
    type: constants.FETCH_AUTOCOMPLETES_SUCCESS,
    payload: { autocompletes, field }
  })
}

function * exportReferences ({ payload: { params } }) {
  const referencesToExport = yield select(selectors.referencesToExport)
  const { data: text, headers } = yield call(
    api.post,
    '/papers/export',
    referencesToExport,
    { params }
  )
  downloadString(text, headers['x-file-name'])
  yield put({ type: constants.EXPORT_REFERENCES_SUCCESS })
}

function * trialActivated () {
  yield call(webApi.post, '/users/activate-trial')
  yield put({
    type: constants.TRIAL_ACTIVATED_SUCCESS
  })
}

function * modalToggled ({ payload: { name } }) {
  const key = `${name}ModalShow`
  const currentValue = yield select(({ ui }) => ui[key])

  if (currentValue) {
    return
  }

  yield delay(600)

  yield put({ type: constants.CLEAR_MODAL_FROM_CONTEXT })
}

function * loadCookiePreference ({ payload: { isDoNotSell } }) {
  const localStorage = getLocalStorage()
  const cookiePreference = localStorage?.getItem('cookiePreference')

  if (isDoNotSell) {
    yield call(webApi.get, '/users/donotsell')
  }

  yield put({
    type: constants.SET_COOKIE_PREFERENCE,
    payload: cookiePreference || 'unknown'
  })

  if (!cookiePreference || cookiePreference === 'unknown') {
    return
  }

  yield call(analytics.setup, cookiePreference)
  yield call(rewardful.initialize, cookiePreference)
  analytics.pageView()

  yield call(initializePostHog, cookiePreference)
}

const fetchChurnKey = function * () {
  const { data } = yield call(webApi.get, '/users/churnkey')
  yield put({
    type: constants.FETCH_CHURN_KEY_SUCCESS,
    payload: data
  })
}

const fetchFullTextLinkSaga = function * () {
  const batch = yield select(selectors.fullTextLinkQueue)
  try {
    const { data } = yield call(
      api.post,
      `${CONFIG.apiURL}/resolve-doi/batch`,
      batch
    )
    yield put({
      type: constants.FETCH_FULL_TEXT_LINK_SUCCESS,
      payload: data.items
    })
    yield put({ type: constants.AVAILABILITY_QUEUE_CLEAR })
  } catch (e) {
    yield put({
      type: constants.FETCH_FULL_TEXT_LINK_FAILURE,
      error: e,
      payload: batch
    })
  }
}

const processAvailabilityQueue = function * () {
  try {
    const { data } = yield call(
      api.post,
      `${CONFIG.apiURL}/article-galaxy/check-availability-batch`,
      { dois: yield select(selectors.agAvailabilityQueue) }
    )

    yield put({
      type: constants.AVAILABILITY_QUEUE_SUCCESS,
      payload: data.items
    })
    yield put({ type: constants.AVAILABILITY_QUEUE_CLEAR })
  } catch (error) {
    console.log(error)
  }
}

const updateAccessLogSaga = function * ({ payload: { doi, url } }) {
  try {
    yield call(api.get, `${CONFIG.apiURL}/resolve-doi/update-access-log?doi=${doi}&url=${url}`)
  } catch (e) {
    console.error(e)
  }
}

export function * geolocateUser () {
  const params = new URLSearchParams({
    fields: 'status,message,country,city,currency,timezone,isp,query',
    key: CONFIG.ipApi.key
  })

  // https://ip-api.com/docs/api:json
  const { data } = yield call(axios.get, `${CONFIG.ipApi.url}/json?${params.toString()}`)

  yield put({
    type: constants.FETCH_GEOLOCATION_SUCCESS,
    payload: data
  })
}

function * validateSession ({ payload: { history, user } }) {
  try {
    yield call(webApi.get, '/users/me')
  } catch (e) {
    if (e.response?.status === 403 && e.response?.data === 'One session at a time.') {
      analytics.event({
        category: 'User',
        action: 'Autologged out',
        label: user?.email
      })
      yield call(webApi.get, '/users/logout')
      const localStorage = getLocalStorage()
      localStorage?.removeItem('apiToken')
      yield put({
        type: constants.LOGOUT_SUCCESS
      })
      history.push('/home')
      yield put(toggleModal('loggedOut', true))
    }
  }
}

export default function * rootSaga () {
  yield takeLatest(constants.TRIAL_ACTIVATED, errorWrap(trialActivated, constants.TRIAL_ACTIVATED_FAILURE))
  yield takeLatest(constants.GET_ENTITLEMENTS, errorWrap(getEntitlements, constants.GET_ENTITLEMENTS_FAILURE))
  yield takeLatest(constants.LOAD_USER, loadUser)
  yield takeLatest(constants.LOGOUT, errorWrap(logout, constants.LOGOUT_FAILURE))
  yield takeLatest(constants.SAVE_USER, errorWrap(saveUser, constants.SAVE_USER_FAILURE))
  yield takeLatest(constants.SAVE_REFERRALS, saveReferrals)
  yield takeLatest(constants.GET_NEW_TOKEN, getNewToken)
  yield takeLatest(constants.CHECK_PREMIUM_IP, errorWrap(checkOrganizationLicense, constants.CHECK_PREMIUM_IP_FAILURE))
  yield takeLatest(
    constants.DELETE_USER,
    errorWrap(
      deleteUser,
      constants.DELETE_USER_FAILURE,
      function * (e) {
        console.error('Unexpected error deleting user: ', e)
        errorToast({
          message: 'Something went wrong deleted your account, try again or contact us at customersupport@researchsolutions.com',
          durationMs: 4000
        })
      }))
  yield takeLatest(constants.LOAD_ANON_TOKEN, loadAnonToken)
  yield takeLatest(constants.REDEEM_CODE, errorWrap(redeemCode, constants.REDEEM_CODE_ERROR))
  yield takeLatest(constants.FETCH_DISCOUNT, errorWrap(fetchDiscount, constants.FETCH_DISCOUNT_ERROR))
  yield takeLatest(constants.APPLY_DISCOUNT, errorWrap(applyDiscount, constants.APPLY_DISCOUNT_ERROR))
  yield takeLatest(constants.COMPLETE_ONBOARDING_STEP, errorWrap(completeOnboardingStep, constants.COMPLETE_ONBOARDING_FAILURE))
  yield takeLatest(
    constants.FETCH_ONBOARDING,
    errorWrap(fetchOnboarding, constants.FETCH_ONBOARDING_FAILURE)
  )

  yield takeLatest(
    constants.EXPORT_REFERENCES,
    errorWrap(exportReferences, constants.EXPORT_REFERENCES_FAILURE)
  )

  yield takeLatest(
    constants.TOGGLE_MODAL,
    modalToggled
  )

  yield takeLatest(
    constants.LOAD_COOKIE_PREFERENCE,
    loadCookiePreference
  )

  yield takeLatest(
    constants.FETCH_CHURN_KEY,
    errorWrap(fetchChurnKey, constants.FETCH_CHURN_KEY_FAILURE)
  )

  yield debounce(
    500,
    constants.FETCH_AUTOCOMPLETES,
    errorWrap(fetchAutocompletes, constants.FETCH_AUTOCOMPLETES_FAILURE)
  )

  yield debounce(
    500,
    constants.FETCH_FULL_TEXT_LINK,
    errorWrap(fetchFullTextLinkSaga, constants.FETCH_FULL_TEXT_LINK_FAILURE)
  )

  yield debounce(
    500,
    constants.AVAILABILITY_QUEUE_APPEND,
    processAvailabilityQueue
  )

  yield takeLatest(
    constants.UPDATE_ACCESS_LOG,
    updateAccessLogSaga
  )

  yield takeLatest(constants.FETCH_GEOLOCATION, errorWrap(geolocateUser, constants.FETCH_GEOLOCATION_FAILURE))

  yield throttle(60000, constants.VALIDATE_SESSION, validateSession)
}
