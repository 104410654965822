import { Component, Suspense } from 'react'
import isEqual from 'lodash.isequal'
import loadable from '@loadable/component'
import { Provider, connect } from 'react-redux'
import { withRouter } from 'react-router'
import qs from 'query-string'
import { Toaster } from 'react-hot-toast'
import Head from './Head'
import Main from './Main'
import UserWayWidget from './UserWayWidget'
import compose from 'client/lib/helpers/compose'
import * as analytics from 'client/lib/analytics'
import * as rollbar from 'client/lib/rollbar'
import * as zendesk from '../../client/lib/zendesk'
import CookieNotice from 'client/components/misc/CookieNotice'
import {
  loadUser, loadBrowser, toggleModal, setPageError,
  handleReferral, extensionLoaded, checkOrganizationLicense,
  setBannerContent, loadAnonToken, exportReferences,
  markTrialActivated, loadCookiePreference
} from 'client/store/ui/actions'
import { loadAssistantSettings } from 'client/store/assistant/actions'
import { setMode as setLoginModalMode } from 'client/store/login-modal/actions'
import { SUBSCRIPTION_STATUS } from '../../server/lib/constants'
import { getLocalStorage } from '../../client/lib/get-local-storage'

const ReferenceCheckModal = loadable(() => import('client/components/modals/ReferenceCheckModal'))
const LoginModal = loadable(() => import('client/components/modals/LoginModal'))
const ResetPasswordModal = loadable(() => import('client/components/modals/ResetPasswordModal'))
const UpgradeModal = loadable(() => import('client/components/modals/UpgradeModal'))
const ExportReferenceModal = loadable(() => import('client/components/modals/ExportReferenceModal'))
const GenerateCitationModal = loadable(() => import('client/components/modals/GenerateCitationModal'))
const AddReportToDashboardModal = loadable(() => import('client/components/modals/AddReportToDashboardModal'))
const FlagModal = loadable(() => import('client/components/modals/FlagModal'))
const CustomDashboardLimitErrorModal = loadable(() => import('client/components/modals/CustomDashboardLimitErrorModal'))
const ShareDashboardModal = loadable(() => import('client/components/modals/ShareDashboardModal'))
const EditDashboardModal = loadable(() => import('client/components/modals/EditDashboardModal'))
const DeleteDashboardModal = loadable(() => import('client/components/modals/DeleteDashboardModal'))
const ExportDashboardModal = loadable(() => import('client/components/modals/ExportDashboardModal'))
const ExportStructuredOutputModal = loadable(() => import('client/components/modals/ExportStructuredOutputModal'))
const ActivateTrialModal = loadable(() => import('client/components/modals/ActivateTrialModal'))
const CancelTrialCheckoutModal = loadable(() => import('client/components/modals/CancelTrialCheckoutModal'))
const CookieModal = loadable(() => import('client/components/modals/CookieModal'))
const PersonaModal = loadable(() => import('client/components/modals/PersonaModal'))
const LoggedOutModal = loadable(() => import('client/components/modals/LoggedOutModal'))

// This is to manage global app lifecycle stuff (eg analytics)
export class App extends Component {
  // Not sure why this is needed, React complains otherwise
  // Error state actually set in componentDidCatch
  static getDerivedStateFromError () {
    return { hasError: true }
  }

  handleUserSubscriptionState = (user) => {
    if (!user || !user?.subscription?.status) {
      return
    }
    const localStorage = getLocalStorage()
    if (localStorage) {
      const subscriptionStatus = localStorage?.getItem('userSubscriptionStatus')
      if (!subscriptionStatus) {
        localStorage.setItem('userSubscriptionStatus', user?.subscription?.status)
        return
      }

      if (subscriptionStatus !== user?.subscription?.status) {
        localStorage.setItem('userSubscriptionStatus', user?.subscription?.status)
      }
      // if the user wasn't previously active and they became active then its a paid conversion event
      if (subscriptionStatus !== SUBSCRIPTION_STATUS.active && user?.subscription?.status === SUBSCRIPTION_STATUS.active) {
        if (typeof (gtag) !== 'undefined') {
          gtag('event', 'conversion', {
            send_to: CONFIG.gtag.subscribeToPlan
          })
        }

        analytics.event({
          category: 'User',
          action: 'Convert to paid'
        })
      }
    }
  }

  handleKeydown = ({ key }) => {
    if (key === 'Enter') {
      const active = document.activeElement
      if (active && !(active.tagName === 'INPUT' && active.type === 'checkbox')) {
        active.click()
      }
    }
  }

  checkForExtension = () => {
    const { extensionLoaded } = this.props
    const marker = document.getElementById('scite-extension-marker')
    if (marker) {
      extensionLoaded()
    }
  }

  checkForSharedBy = () => {
    const { location } = this.props
    const query = qs.parse(location.search)
    const localStorage = getLocalStorage()
    if (localStorage && query.shared_by) {
      localStorage.setItem('sharedBy', query.shared_by)
    }
  }

  // ensure that users have initial referral params
  // in local storage
  checkHasReferralParams = () => {
    const localStorage = getLocalStorage()
    if (localStorage) {
      if (localStorage.getItem('utm_medium')) {
        return
      }
      const { location } = this.props
      const query = qs.parse(location.search)

      const referral = document?.referrer
      let medium = query?.utm_medium
      if (!referral) {
        medium = 'direct'
      } else if (CONFIG.organicTrafficSources?.some(source => referral.includes(source))) {
        medium = 'organic'
      } else {
        medium = 'referral'
      }
      const source = query?.utm_source || referral || 'none'
      const campaign = query?.utm_campaign || ''

      localStorage.setItem('utm_medium', medium)
      localStorage.setItem('utm_source', source)
      localStorage.setItem('utm_campaign', campaign)
    }
  }

  componentDidMount () {
    const {
      loadUser,
      loadBrowser,
      toggleModal,
      handleReferral,
      setLoginModalMode,
      checkOrganizationLicense,
      loadAnonToken,
      history,
      user,
      loadCookiePreference,
      loadAssistantSettings,
      location: { search }
    } = this.props

    handleReferral()

    const query = qs.parse(search)

    if (query.signup) {
      setLoginModalMode('register')
      toggleModal('login', true)
    } else if (query.login) {
      setLoginModalMode('login')
      toggleModal('login', true)
    }

    history.listen(() => analytics.pageView())
    history.listen(rollbar.handlePageChange)

    zendesk.setup()
    rollbar.setup()

    if (user) {
      analytics.setUser(user)
    }

    loadUser({ query })

    loadCookiePreference()
    loadAssistantSettings()

    loadBrowser()
    checkOrganizationLicense()

    if (query.authToken) {
      loadAnonToken(query.authToken)
    }

    //
    // Check first in case the event already fired
    // which I assume is possible.
    //
    this.checkForExtension()
    window.addEventListener('scite-extension/loaded', this.checkForExtension)

    window.addEventListener('keydown', this.handleKeydown)

    this.checkForSharedBy()
    this.checkHasReferralParams()
  }

  componentDidUpdate (prevProps) {
    const {
      user,
      toggleModal,
      markTrialActivated,
      location: { search }
    } = this.props
    const query = qs.parse(search)

    // We intentionally use lodash for a deep equality check
    //   to avoid sending dupe events here when two objects aren't
    //   compared properly for equality!
    if (user && !isEqual(user, prevProps.user)) {
      analytics.setUser(user)
    }

    if (user && !user?.activatedTrial && query.create_trial) {
      markTrialActivated()
      toggleModal('persona', true)
      analytics.event({
        category: 'User',
        action: 'Activated Trial'
      })
    }

    if (user && !user?.activatedTrial && query.trial_create_cancelled) {
      toggleModal('cancelTrialCheckout', true)
      analytics.event({
        category: 'User',
        action: 'Cancelled Trial Activation'
      })
    }

    this.handleUserSubscriptionState(user)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('scite-extension/loaded', this.checkForExtension)
  }

  componentDidCatch (appError, info) {
    const { setPageError } = this.props
    console.error(appError)
    if (typeof Rollbar !== 'undefined') {
      Rollbar.error('React uncaught error', appError)
    }
    setPageError({ status: 500 })
  }

  render = () => {
    const { shownModals } = this.props

    const modals = [
      ['login', LoginModal, {}],
      ['upgrade', UpgradeModal, {}],
      ['resetPassword', ResetPasswordModal, {}],
      ['referenceCheck', ReferenceCheckModal, {}],
      ['exportReference', ExportReferenceModal, {
        actionCreator: exportReferences
      }],
      ['generateCitation', GenerateCitationModal, {
        actionCreator: exportReferences
      }],
      ['addReportToDashboard', AddReportToDashboardModal, {}],
      ['flag', FlagModal, {}],
      ['dashboardLimitError', CustomDashboardLimitErrorModal, {}],
      ['shareDashboard', ShareDashboardModal, {}],
      ['editDashboard', EditDashboardModal, {}],
      ['deleteDashboard', DeleteDashboardModal, {}],
      ['exportDashboard', ExportDashboardModal, {}],
      ['exportStructuredOutput', ExportStructuredOutputModal, {}],
      ['activateTrial', ActivateTrialModal, {}],
      ['cancelTrialCheckout', CancelTrialCheckoutModal, {}],
      ['cookie', CookieModal, {}],
      ['persona', PersonaModal, {}],
      ['loggedOut', LoggedOutModal, {}]
    ]

    return (
      <>
        <Head />
        <UserWayWidget />
        <Main />

        {
          modals
            .filter(([name, Modal]) => shownModals.includes(name))
            .map(([name, Modal, props]) => (
              <Modal key={name} {...props} />
            ))
        }
        <Toaster />
        <CookieNotice />
      </>
    )
  }
}

const mapStateToProps = ({ ui }) => ({
  user: ui.user,
  fetchingUser: ui.fetchingUser,
  shownModals: ui.shownModals
})
const actionCreators = {
  loadUser,
  loadBrowser,
  handleReferral,
  toggleModal,
  setLoginModalMode,
  setPageError,
  extensionLoaded,
  checkOrganizationLicense,
  setBannerContent,
  loadAnonToken,
  markTrialActivated,
  loadCookiePreference,
  loadAssistantSettings
}
const WrappedApp = compose(
  connect(mapStateToProps, actionCreators),
  withRouter
)(App)

const AppWithStore = ({ store, ...rest }) => (
  <Provider store={store}>
    <Suspense fallback={<div>React be loadin...</div>}>
      <WrappedApp {...rest} />
    </Suspense>
  </Provider>
)

export default AppWithStore
